<template>
  <div>
    <div class="tab-contents-wrap">
      <!-- Event List -->
      <div class="board-list-wrap">
        <template v-if="statusType === true">
          <div class="board-top-area">
            <div class="total-count">
              전체
              <em class="space">{{events.length}}</em>건
            </div>
          </div>
          <ul class="board-list">
            <template v-for="(v, i) in events.slice(0, increase)">
              <li :key="i">
                <div class="list-item">
                  <dl class="info">
                    <dt>
                      <router-link
                        :to="{ name: 'EventDetail', params: { eventNo: v.eventId, event: v } }"
                      >{{v.eventName}}</router-link>
                    </dt>
                    <dd>
                      <ul class="info-detail">
                        <li class="time">
                          <!-- <time>2020.07.13(월) 19:00 - 20:30</time> -->
                          <time>
                            {{ getDateString(v.startDate.replace(/\./g, '-'), v.endDate.replace(/\./g, '-')) }}
                          </time>
                        </li>
                        <li class="date">
                          <span>{{v.placeName || v.placeDetail}}</span>
                        </li>
                        <li class="right-area status">
                          <!-- 지원 취소 -->
                          <template v-if="v.cancelDate">
                            <span>{{$t('content.users.ServiceInfo.txt15')}}</span>
                          </template>
                          <!-- 행사 취소 -->
                          <template v-else-if="v.eventCancelDate">
                            <span>{{$t('content.users.ServiceInfo.txt20')}}</span>
                          </template>
                          <!-- 행사 전 -->
                          <template
                            v-else-if="$moment.duration($moment().diff($moment(v.startDate.replace(/\./g, '-'))))
                            .asMilliseconds() < 0">
                              <!-- 취소 -->
                            <button
                              @click="cancelEvent(v.id)"
                              type="button"
                              class="btn-sm"
                            >{{$t('content.users.ServiceInfo.txt03')}}</button>
                          </template>
                          <template v-else>
                              <!-- 행사완료 -->
                            <span>{{$t('content.users.ServiceInfo.txt04')}}</span>
                          </template>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
            </template>
          </ul>
            <div class="news_more">
                <button class="btn_more" @click="addEvent" v-if="events.slice(0, increase).length !== events.length">
                    <span class="ico_more">+</span>
                    <span class="txt_more">more</span>
                </button>
            </div>
          <div class="btn_bottom_wrap" >
            <button @click="goBack" class="btn-ghost">Back</button>
            <!-- <button class="btn-more" @click="addEvent" v-if="events.slice(0, increase).length !== events.length">
              <span class="circle">
                <span class="icon-plus">+</span>
              </span>
              <em class="btn-text">더보기</em>
        </button> -->
          </div>
        </template>
        <template v-else>
          <div class="no-list-wrap">
            <div class="no-list">
              <h6 data-aos="flip-left" class="aos-init aos-animate">{{$t('content.event.Event.txt65')}}</h6>
            </div>
            <div class="btn_bottom_wrap" >
              <button @click="goBack" class="btn-ghost">Back</button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { APIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion, format } from '@/common/helperUtils';

export default {
  name: 'MyHistory',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {},
  data() {
    return {
      events: [],
      increase: 10,
      statusType: true,
    };
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    this.retrieveEvent();
  },
  methods: {
    retrieveEvent() {
      this.portalApiClient({
        url: APIs.EVENT_MY_APPLY_LIST,
        method: 'post',
        data: {
          data: {
            memberId: this.userInfo.id
          }
        }
      }).then(({ data }) => {
        if (data.result) {
          this.events = data.list.reverse();
          this.statusType = !!data.list.length;
        } else {
          this.showAlert(data.message);
        }
      });
    },
    cancelEvent(applyId) {
      this.showConfirm(this.$t('confirmMessage.canceled'), () => {
        this.portalApiClient({
          url: `${APIs.MYPAGE_EVENT_CANCEL}/${applyId}`,
          method: 'DELETE',
          data: {
            id: applyId
          },
        }).then(({ data }) => {
          if (data.result) {
            this.showAlert(this.$t('alertMessage.canceldEvent'));
            this.retrieveEvent();
            this.$parent.$emit('refresh');
          } else {
            this.showAlert(data.message);
          }
        });
      });
    },
    addEvent() {
      this.increase += 10;
    },
    goBack() {
      this.$router.go(-1);
    },
    getDateString(startDate, endDate) {
      // 이벤트 기간 타이틀 문자열
      if (format.date(startDate) == format.date(endDate)) {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.time(this.$moment(endDate).format('HHmm'))}`;
      } else {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.date(endDate)} ${format.time(this.$moment(endDate).format('HHmm'))}`;
      }
    }
  },
};
</script>
<style>
.board-top-area{ padding-bottom: 1rem;}
.board-list .list-item .info .info-detail .status .btn-sm{ color: #3190d6; font-weight: 700;}
.no-list-wrap{ margin-top: 4rem;}
</style>
